.AddAlb_Content_Box {
  border-radius: 5px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
  padding: 20px;
  width: 100%;
  background-color: var(--white);
}

.AddAlb_Heading {
  display: block;
  font-size: 1.5em;
  font-weight: 600;
  margin-bottom: 20px;
}

.AddAlb_Inputs_Box {
  display: flex;
  gap: 20px;
  margin-bottom: 15px;
}

.AddAlb_AlbumName {
  width: 100%;
}

.AddAlb_SelectOptions_Box {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.AddAlb_Select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.AddAlb_Date {
  display: block;
  padding: 10px 20px 10px 20px;
}

.AddAlb_ImgPreview_Grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
  gap: 10px;
  height: auto;
  background-color: rgb(231, 231, 231);
}

.AddAlb_ImgPreview_Wrapper {
  position: relative;
  width: auto;
  height: 150px;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.AddAlb_ImgPreview_Wrapper > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.AddAlb_Controls_Box {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.AddAlb_Controls_Box > span {
  color: lightgray;
  font-style: italic;
}

.AddAlb_Controls_btns {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 20px;
  width: 100%;
}

.AddAlb_Loading {
  width: 100px;
}

/* ------------------------------------- ↓↓ */

/* ---------------------------------------------------------- */
/* ----- Media Queries ↓ ------------------------------------ */
/* ---------------------------------------------------------- */

@media all and (max-width: 1000px) {
  .AddAlb_SelectDropdown {
    width: 100%;
  }

  .AddAlb_Inputs_Box {
    flex-direction: column;
  }

  .AddAlb_Date {
    padding: 10px 20px 10px 20px;
    width: 50%;
  }
}

@media all and (max-width: 700px) {
  .AddAlb_Inputs_Box {
    gap: 10px;
  }

  .AddAlb_Date {
    width: 100px;
    display: block;
  }
}

/* Mobile Devices ↓ */

@media all and (max-width: 500px) {
  .AddAlb_ImgPreview_Grid {
    grid-template-columns: repeat(3, 1fr);
  }

  .AddAlb_Heading {
    font-size: 1.2em;
  }

  .AddAlb_Content_Box {
    padding: 10px;
  }

  .AddAlb_ImgPreview_Wrapper {
    height: 80px !important;
    padding: 0;
    height: auto !important;
  }

  .AddAlb_Controls_Box {
    flex-direction: column-reverse;
    align-items: center;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 2rem;
  }

  .AddAlb_Controls_Box > span {
    margin-top: 20px;
  }

  .AddAlb_Controls_Btn {
    width: 100%;
  }
}
