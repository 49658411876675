.imgList {
  position: relative;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  width: 100%;
  height: auto;
}

/* --------- ↓ */

.imgListItem {
  position: relative;
  width: 100%;
  height: 200px;
  border-radius: 5px;
  overflow: hidden;
  background-color: #e4e4e4;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imgListItem:hover {
  cursor: pointer;
  transition: all ease-in-out;
  filter: brightness(90%);
}

/* --------- ↓ */

.imgListItemBar {
  position: absolute;
  bottom: 0;
  padding: 15px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  background-color: rgba(0, 0, 0, 0.5);
}

.imgListItemMeta {
  display: flex;
  flex-direction: column;
}

.imgListBarTitle {
  font-size: 0.9em;
  font-weight: 500;
  margin-bottom: 5px;
  color: #ffffff;
}

.imgListBarTotalItems {
  font-size: 0.8em;
  font-weight: 300;
  color: #ffffff;
}

.imgListBarDateCreated {
  font-size: 0.8em;
  font-weight: 300;
  color: lightgray;
}

.imgSrc {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* ---------------------------------------------------------- */
/* ----- Media Queries ↓ ------------------------------------ */
/* ---------------------------------------------------------- */
/* Laptop ↓ */

@media all and (max-width: 1350px) {
  .imgListItem {
    height: 180px;
  }
}

@media all and (max-width: 1300px) {
  .imgListItem {
    height: 170px;
  }
}

@media all and (max-width: 1250px) {
  .imgListItem {
    height: 160px;
  }
}

@media all and (max-width: 1200px) {
  .imgListItem {
    height: 150px;
  }
}

/* Ipads & Tablets ↓ */

@media all and (max-width: 1150px) {
  .imgList {
    grid-template-columns: repeat(4, 1fr);
  }

  .imgListItem {
    height: 180px;
  }
}

@media all and (max-width: 1100px) {
  .imgListItem {
    height: 170px;
  }
}

@media all and (max-width: 1050px) {
  .imgListItem {
    height: 160px;
  }
}

@media all and (max-width: 1000px) {
  .imgList {
    grid-template-columns: repeat(3, 1fr);
  }

  .imgListItem {
    height: 180px;
  }
}

@media all and (max-width: 950px) {
  .imgListItem {
    height: 170px;
  }
}

@media all and (max-width: 900px) {
  .imgListItem {
    height: 160px;
  }
}

@media all and (max-width: 850px) {
  .imgListItem {
    height: 150px;
  }
}

@media all and (max-width: 800px) {
  .imgList {
    grid-template-columns: repeat(2, 1fr);
  }

  .imgListItem {
    height: 180px;
  }
}

@media all and (max-width: 750px) {
  .imgListItem {
    height: 170px;
  }
}

@media all and (max-width: 700px) {
  .imgListItem {
    height: 200px;
  }
}

@media all and (max-width: 650px) {
  .imgListItem {
    height: 190px;
  }
}

@media all and (max-width: 650px) {
  .imgListItem {
    height: 180px;
  }
}

@media all and (max-width: 600px) {
  .imgListItem {
    height: 170px;
  }
}

@media all and (max-width: 550px) {
  .imgListItem {
    height: 170px;
  }
}

/* Mobile Devices ↓ */

@media all and (max-width: 500px) {
  .imgList {
    gap: 10px !important;
  }

  .imgListItem {
    height: 150px;
  }

  .imgListBarTitle {
    font-size: 0.85em;
  }
}