.AddTml_Content_Box {
  border-radius: 5px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
  padding: 20px;
  width: 100%;
  background-color: var(--white);
}

.AddTml_Heading {
  display: block;
  font-size: 1.5em;
  font-weight: 600;
  margin-bottom: 20px;
}

.AddTml_ImgPreview_Grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
  gap: 10px;
  height: auto;
  background-color: rgb(231, 231, 231);
}

.AddTml_ImgPreview_Wrapper {
  position: relative;
  width: auto;
  height: 150px;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  padding: 20px;
  height: 300px !important;
}

.AddTml_ImgPreview_Wrapper > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.AddTml_Bottom_Box {
  margin-top: 30px;
  display: flex;
  align-items: flex-end;
}

.AddTml_Bottom_Box > span {
  color: lightgray;
  font-style: italic;
}

.AddTml_Controls_Box {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 20px;
  width: 100%;
}

.AddTml_Loading {
  width: 100px;
}

/* ---------------------------------------------------------- */
/* ----- Media Queries ↓ ------------------------------------ */
/* ---------------------------------------------------------- */

@media all and (max-width: 500px) {
  .AddTml_Content_Box {
    padding: 10px;
    margin-bottom: 2rem;
  }

  .AddTml_Heading {
    font-size: 1.2em;
  }

  .AddTml_ImgPreview_Grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .AddTml_ImgPreview_Wrapper {
    height: 80px !important;
    padding: 0;
    height: auto !important;
  }

  .AddTml_Bottom_Box {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    width: 100%;
    margin-top: 20px;
  }

  .AddTml_Bottom_Box > span {
    margin-top: 20px;
  }
}
