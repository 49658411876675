.comment-actions_con {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px;
  border-bottom: solid 1px #dadada;
  background-color: white;
}

.action_button {
  display: flex;
  width: 100%;
  border: none;
  padding: 5px;
  font-size: 0.9rem;
  font-weight: 600;
  color: rgb(143, 143, 143);
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.action_button:hover {
  cursor: pointer;
  background-color: rgb(240, 240, 240);
}

.comment-actions-meta_con {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: white;
}

.comment-actions-meta_con > a {
  font-size: 0.9rem;
  font-weight: 400;
  color: rgb(143, 143, 143);
  text-decoration: none;
}

.comment-actions-meta_con > a:hover {
  cursor: pointer;
  text-decoration: underline;
}

.action_like-con {
  position: relative;
}

.action_reaction-ul {
  position: absolute;
  display: flex;
  left: 0;
  bottom: 40px;
  background-color: aqua;
}

.react-action_box {
  position: relative;
  padding: 10px;
  border-bottom: solid 1px rgb(224, 224, 224);
  display: flex;
  justify-content: space-between;
}

.react-action_box:first-child {
  border-top: none;
}

.react-action_icon {
  fill: gray;
}

.react-dropmenu_ul {
  position: absolute;
  bottom: 45px;
  margin: 0;
  padding: 0 5px;
  border-radius: 150px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  background: #fff;
}

.react-dropmenu_ul li {
  display: inline-block;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(0.8) translateY(0);
  background-size: cover;
  transition: transform 200ms ease;
  position: relative;
  font-size: 1.6rem;
  cursor: pointer;
}
.react-dropmenu_ul li.like {
  background-position: 0 -144px;
}
.react-dropmenu_ul li.love {
  background-position: 0 -192px;
}
.react-dropmenu_ul li.haha {
  background-position: 0 -96px;
}
.react-dropmenu_ul li.wow {
  background-position: 0 -288px;
}
.react-dropmenu_ul li.sad {
  background-position: 0 -240px;
}
.react-dropmenu_ul li.angry {
  background-position: 0 0;
}
.react-dropmenu_ul li:before {
  content: attr(data-hover);
  position: absolute;
  bottom: 120%;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  padding: 0 8px;
  border-radius: 20px;
  font-family: Helvetica, Verdana, Arial;
  font-weight: bold;
  line-height: 20px;
  font-size: 12px;
  background: rgba(0, 0, 0, 0.76);
  display: none;
}
.react-dropmenu_ul li:hover {
  transform: scale(1) translateY(-5px);
  transition: transform 200ms ease;
}
.react-dropmenu_ul li:hover:before {
  display: inline-block;
}
