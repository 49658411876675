header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  z-index: 5000;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 4px;
  background-color: var(--white);
}

nav {
  display: flex;
  padding: 0 40px 0 40px;
  justify-content: space-between;
  width: 100%;
  margin: auto;
}

.logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
}

.logo-label {
  font-weight: 900;
  font-size: 1.7rem;
  color: var(--primary);
}

.ul_navbar {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  gap: 1.5rem;
}

.ul_navbar > li {
  position: relative;
  font-weight: 600;
  color: var(--black);
  padding: 0 !important;
  display: flex;
}

.nav-labels {
  display: block;
  color: var(--black);
}

.menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.3rem;
  border-radius: 5px;
}

.menu > img {
  width: 30px;
  height: 30px;
}

.nav_icon {
  width: 25px;
  height: 25px;
}

.menu:hover {
  cursor: pointer;
  transition: all ease 150ms;
}

.ul_menu-dropdown {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 45px;
  right: 0;
  width: 200px;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  z-index: 20000;
  background-color: var(--white);
}

.ul_menu-dropdown > li {
  padding: 15px;
  width: 100%;
  border-bottom: solid 1px var(--menuHover);
  text-align: center;
}

.ul_menu-dropdown > li:last-child {
  border-bottom: none;
}

.ul_menu-dropdown > li:hover {
  cursor: pointer;
  transition: all ease 200ms;
  background-color: var(--menuHover);
}

/* ---------------------------------------------------------- */
/* ----- Media Queries ↓ ------------------------------------ */
/* ---------------------------------------------------------- */

@media all and (max-width: 1200px) {
  nav {
    padding: 0;
    width: 90%;
  }
}

/* Ipads & Tablets ↓ */

@media all and (max-width: 800px) {
  .logo-label {
    font-size: 1.2em;
  }

  .menu > img,
  .nav_icon {
    width: 25px;
    height: 25px;
  }

  .ul_navbar {
    gap: 20px;
  }

  .ul_navbar > li {
    display: flex;
    flex-direction: column;
    gap: 0;
  }

  .nav-labels {
    font-size: 0.6rem;
  }
}
