html {
  scroll-behavior: smooth;
}

.adv_banner_con {
  margin-top: 60px;
  width: 100%;
  height: 640px;
  background-image: url("./images/you-yangs_1920x640.jpg");
  background-color: rgb(245, 245, 245);
}

.adv_banner-content_con {
  width: 900px;
  height: 100%;
  display: flex;
  margin: auto;
  align-items: flex-end;
}

.adv_banner-heading_con {
  width: 100%;
  padding: 2rem;
  background-color: rgba(0, 0, 0, 0.5);
}

.adv_banner_heading {
  font-size: 2.5rem;
  color: white;
  font-weight: 400;
  text-align: center;
}

/* --- 📌Main Content ↓ */

.adv_content {
  width: 900px;
  display: block;
  margin: auto;
  padding: 4rem 0 4rem 0;
}

.adv_place_card {
  margin-top: 4rem;
}

.adv_place-img_con {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 400px;
  background-color: rgb(245, 245, 245);
}

.adv_place-img_con > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.adv_place-more-photos_btn {
  position: absolute;
  bottom: 2rem;
  right: 2rem;
}

.adv_place-title {
  font-size: 2rem;
  margin-top: 2rem;
}

.adv_place-location {
  font-size: 1.3rem;
  font-weight: 400;
  margin-top: 1rem;
}

.adv_place-summary {
  margin-top: 2rem;
}

.adv_place-meta_con {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}

.adv_place-meta_con span {
  display: flex;
}

.adv_place-meta_con span p:first-child {
  font-weight: 600;
  margin-right: 0.5rem;
}

.adv_place-more-photos_btn {
  border: solid 2px var(--white);
  padding: 1rem;
  border-radius: 5px;
  color: var(--white);
  font-weight: 600;
  opacity: 0.9;
  background-color: rgba(0, 0, 0, 0.5);
}

.adv_place-more-photos_btn:hover {
  cursor: pointer;
  transition: all ease-in-out 150ms;
  opacity: 1;
}

.adv_destination-menu_con {
  position: sticky;
  bottom: 0;
  padding: 2rem;
}

.adv_destination-menu_ul {
  position: absolute;
  right: 2rem;
  bottom: 6.5rem;
  display: flex;
  flex-direction: column;
  width: fit-content;
  border-radius: 5px;
  padding: 5px;
  min-height: auto;
  max-height: 50vh;
  overflow-y: auto;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  background-color: var(--white);
}

.adv_destination-menu_ul > li {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 5px;
  font-size: 0.85rem;
  font-weight: 600;
}

.adv_destination-menu_ul > li:hover {
  cursor: pointer;
  border-radius: 5px;
  transition: all ease-in-out 50ms;
  background-color: var(--menuHover);
}

.explore-icon {
  position: sticky;
  display: block;
  bottom: 2rem;
  margin-left: auto;
  font-size: 4rem;
  fill: var(--primary);
  border-radius: 50%;
  padding: 0.5rem;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  cursor: pointer;
  background-color: var(--white);
}

.adv_destination-menu_a,
.adv_destination-menu_a:hover {
  all: unset;
}

/* --- 📌Media Queries ↓ */

@media all and (max-width: 1500px) {
  .adv_banner_con {
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center;
  }
}

@media all and (max-width: 950px) {
  .adv_banner_con {
    height: 60vh;
  }

  .adv_banner-content_con {
    width: 100%;
  }

  .adv_banner_heading {
    line-height: 1.5;
  }

  .adv_content {
    width: 90%;
  }
}

@media all and (max-width: 500px) {
  .adv_banner_con {
    background-position: 70% 0;
  }

  .adv_place-img_con {
    height: 250px;
  }

  .adv_banner_heading {
    font-size: 2rem;
  }

  .adv_place-more-photos_btn {
    padding: 0.5rem;
    font-size: 0.85rem;
  }

  .adv_place-more-photos_btn {
    bottom: 1rem;
    right: 1rem;
  }

  .adv_destination-menu_con {
    position: sticky;
    bottom: 0;
    padding: 1.5rem;
  }
}

@media all and (max-height: 500px) {
  .adv_banner_con {
    height: 60vh;
  }

  .adv_banner_heading {
    font-size: 1.6rem;
  }

  .adv_place-img_con {
    height: 250px;
  }
}
