/* ------------------------------------------------------------ */
/* ----- Photo Types ------------------------------------------ */
/* ------------------------------------------------------------ */

.PL-Photos_Heading {
  display: block;
  font-size: 2em;
  font-weight: 700;
  margin-bottom: 15px;
}

.PL-Photos_PhotoTypes_Grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

.PL-Photos_PhotoTypes_Card {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
}

.PL-Photos_CardImg_Wrapper {
  width: 100%;
  height: 180px;
  border-radius: 5px;
  border: solid 1px rgb(224, 224, 224);
  overflow: hidden;
}

.PL-Photos_CardImg_Wrapper > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: var(--black);
}

.PL-Photos_CardImg_Wrapper > img:hover {
  cursor: pointer;
  transition: all ease-in-out;
  filter: brightness(90%);
}

.PL-Photos_PhotoTypesMeta_Box {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  background-color: var(--white);
}

.PL-Photos_PhotoTypesMeta_Box > span:nth-child(1) {
  font-weight: 600;
}

.PL-Photos_LazyLoadWrapper > img {
  object-fit: cover;
}

/* ------------------------------------------------------------ */
/* ----- All Photos ------------------------------------------- */
/* ------------------------------------------------------------ */

.PL-Photos_Grouping {
  padding: 20px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
  margin: auto;
  background-color: var(--white);
}

.PL-Photos_Grouping.photo-listing {
  margin-top: 20px;
}

.alb_photo-list_pub {
  display: flex;
  font-size: 1.2em;
}

.PL-Photos_AlbumHeader_Box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.PL-Photos_AlbumTop_Box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.PL-Photos_Filter {
  display: flex;
  flex-direction: row;
  gap: 5px;
  font-weight: 500;
  cursor: pointer;
}

.PL-Photos_Filter > .icon {
  font-weight: 600;
  font-size: 20px;
}

ul.PL-Photos_FilterDropmenu {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
  background-color: #fff;
}

ul.PL-Photos_FilterDropmenu > li.item {
  padding: 5px 15px 5px 15px;
  border-radius: 50px;
  width: auto;
  font-size: 0.85em;
  font-weight: 500;
  border: solid 1px lightgray;
}

ul.PL-Photos_FilterDropmenu > li.item:hover {
  cursor: pointer;
  transition: all ease 150ms;
  background-color: var(--menuHover);
}

/* ---------------------------------------------------------- */
/* ----- Media Queries ↓ ------------------------------------ */
/* ---------------------------------------------------------- */
@media all and (max-width: 1250px) {
  .PL-Photos_Grouping {
    width: 95%;
  }

  .PL-Photos_CardImg_Wrapper {
    height: 160px;
  }
}

/* Ipads & Tablets ↓ */

@media all and (max-width: 1150px) {
  .PL-Photos_PhotoTypes_Grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media all and (max-width: 950px) {
  .PL-Photos_CardImg_Wrapper {
    height: 150px;
  }
}

@media all and (max-width: 850px) {
  .PL-Photos_CardImg_Wrapper {
    height: 140px;
  }
}

@media all and (max-width: 800px) {
  .PL-Photos_PhotoTypes_Grid {
    grid-template-columns: repeat(3, 1fr);
  }

  .PL-Photos_CardImg_Wrapper {
    height: 160px;
  }
}

@media all and (max-width: 750px) {
  .PL-Photos_CardImg_Wrapper {
    height: 150px;
  }
}

@media all and (max-width: 700px) {
  .PL-Photos_CardImg_Wrapper {
    height: 170px;
  }
}

@media all and (max-width: 650px) {
  .PL-Photos_CardImg_Wrapper {
    height: 160px;
  }
}

@media all and (max-width: 600px) {
  .PL-Photos_CardImg_Wrapper {
    height: 150px;
  }
}

@media all and (max-width: 550px) {
  .PL-Photos_CardImg_Wrapper {
    height: 140px;
  }
}

/* Mobile Devices ↓ */

@media all and (max-width: 500px) {
  .PL-Photos_Grouping {
    padding: 10px;
  }

  .PL-Photos_Grouping.photo-listing {
    margin-top: 10px;
  }

  .PL-Photos_PhotoTypes_Grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }

  .PL-Photos_CardImg_Wrapper {
    height: 150px;
  }
}

@media all and (max-width: 300px) {
  .PL-Photos_PhotoTypes_Grid {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}
