.PR-Alb_Content_Box {
  display: block;
  margin: auto;
}

.PR-Alb_Header_Box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
  background-color: white;
}

.PR-Alb_AddAlbum {
  display: flex;
  align-items: center;
  font-weight: 600;
}

.PR-Alb_AddAlbum:hover {
  cursor: pointer;
  text-decoration: underline;
}

/* --- Album List ↓ */

.album_album-list_con {
  padding: 20px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
  background-color: white;
}

ul.PR-Alb_FilterByCategory_Ul {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
  margin-bottom: 20px;
  background-color: #fff;
}

ul.PR-Alb_FilterByCategory_Ul > li {
  padding: 5px 15px 5px 15px;
  border-radius: 50px;
  width: auto;
  font-size: 0.85em;
  font-weight: 500;
  border: solid 1px lightgray;
}

ul.PR-Alb_FilterByCategory_Ul > li:hover {
  cursor: pointer;
  transition: all ease 150ms;
  background-color: var(--menuHover);
}

.PR-Album_LazyLoadWrapper > img {
  object-fit: cover;
}

.PR-Dash_CopyIcon {
  position: absolute;
  left: 15px;
  top: 15px;
  font-size: 25px;
}

.alb_menu_con {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
}

button.alb_menu_btn {
  padding: 3px;
  width: 30px;
  height: 30px;
  font-size: 2rem;
  display: flex;
  align-items: center;
  border-radius: 50%;
  border: none;
  justify-content: center;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  background-color: white;
}

ul.alb_menu_dropdown {
  position: absolute;
  top: 30px;
  right: 0;
  display: flex;
  flex-direction: column;
  padding: 5px;
  width: 120px;
  border-radius: 5px;
  z-index: 2;
  box-sizing: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  background: white;
}

ul.alb_menu_dropdown > li {
  padding: 5px;
  border-radius: 5px;
  font-size: 0.9rem;
  width: 100%;
  font-weight: 500;
}

ul.alb_menu_dropdown > li:hover {
  cursor: pointer;
  transition: all ease 100ms;
  background-color: var(--menuHover);
}

/* --- Album List Files ↓ */

.album_album-list-files_con {
  padding: 20px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
  background-color: white;
}

.album_files-header_con {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.album_files-title {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: 600;
}

.album_files-date {
  color: lightgray;
  font-weight: 300;
  font-size: 0.9rem;
}

.album_files-btns_con > button {
  border-radius: 5px;
  padding: 3px 5px 3px 5px;
  min-width: 100px;
  border: solid 2px var(--primary);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  margin-left: 1rem;
  font-weight: 600;
}

.album_files-btns_con > button:hover {
  cursor: pointer;
  transition: all ease 150ms;
  opacity: 0.95;
}

.album_files-save_btn {
  border: none;
  color: white;
  background-color: var(--primary);
}

/* ---------------------------------------------------------- */
/* ----- Media Queries ↓ ------------------------------------ */
/* ---------------------------------------------------------- */

@media all and (max-width: 500px) {
  .PR-Alb_Content_Box {
    grid-template-columns: repeat(3, 1fr);
  }

  .album_album-list_con {
    padding: 10px;
  }

  .PR-Alb_Header_Box{
    margin-bottom: 10px !important;
  }
}
