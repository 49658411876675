.Button {
  display: block;
  padding: 10px;
  border: none;
  border-radius: 5px;
  height: 50px;
  box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
  font-weight: 600;
  font-size: 1.1em;
  min-width: 130px !important;
  background: transparent;
}

.Button:hover {
  cursor: pointer;
}

.Button.Center {
  margin: auto;
}

.Button.Left {
  margin-right: auto;
}

.Button.Right {
  margin-left: auto;
}

.Button.Small {
  width: 25%;
}

.Button.Medium {
  width: 50%;
}

.Button.Large {
  width: 100%;
}

.Button.Filled {
  color: var(--white);
  background-color: var(--primary);
}

.Button.Outlined {
  color: var(--primary);
  border: solid 2px var(--primary);
}

.Button.None {
  border: none;
  box-shadow: none;
}

@media all and (max-width: 500px) {
  .Button {
    width: 100% !important;
    height: 40px;
    line-height: 1;
  }
}
