.comment_main-content__con {
  padding: 10px;
}

.comment_main-content__con:empty {
  display: none;
}

.comment_single-main__con {
  margin-bottom: 10px;
}

.comment_root-avatar__wrapp {
  min-width: 25px;
  min-height: 25px;
  max-width: 25px;
  max-height: 25px;

  border-radius: 50%;
  overflow: hidden;
  background-color: rgb(240, 240, 240);
}

.comment_root-avatar__wrapp > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.comment_enter-comment__form {
  display: flex;
  align-items: center;
  width: 100%;
}

.comment_root-comment-input__con {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  background-color: white;
}

.comment_root-comment-input__con.reply {
  margin-top: 5px;
}
.comment_enter-comment__button {
  width: auto;
  height: auto;
  border: none;
  line-height: 0;
  cursor: pointer;
  background-color: transparent;
}

.icon_send {
  width: 25px;
  height: 25px;
  fill: var(--primary);
}

.writer-detail {
  display: flex;
  flex-direction: row;
}

.author {
  padding: 3px;
  font-size: 0.8rem;
  font-weight: 600;
  padding: 0;
}

.avatar {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  overflow: hidden;
  background-color: rgb(236, 236, 236);
}

.avatar > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.comment_author-detail_con {
  display: flex;
}

.timestamp {
  display: block;
  font-size: 0.75rem;
  margin-left: 5px;
  color: gray;
  padding: 0;
}

.single-comment {
  display: flex;
}

.col-content {
  width: 100%;
  margin-left: 5px;
  border-radius: 10px;
  padding: 10px;
  max-width: calc(100% - 30px) !important;
  background-color: aliceblue;
}

.col-content > .actions {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 3px;
}

.col-content > .actions > button {
  border: none;
  color: gray;
  font-size: 0.7rem;
  background-color: transparent;
}

.actions > button:hover {
  cursor: pointer;
  color: #303030;
}

.single-comment .reply-to {
  display: block;
  font-size: 0.8rem;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: lightgray;
  font-size: 0.9rem;
  opacity: 1; /* Firefox */
}

.replyComment_single-comment__con {
  margin-left: 20px;
}
