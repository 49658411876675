.TextWithLineBreaksReadMore {
  margin-top: 5px;
  overflow-y: auto;
  max-height: 70vh;
}

.ReadMore {
  white-space: pre-line;
  color: #303030;
  font-size: 0.9rem;
}

.ReadMore_Btn {
  color: rgb(148, 148, 148);
  padding: 0;
  min-width: 0;
  box-shadow: none;
  font-size: 0.85em;
  border: none;
  margin-left: 5px;
  cursor: pointer;
  background-color: transparent;
}
