.selectDropdown {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: solid 1px lightgray;
  outline-style: none;
  cursor: pointer;
}

.selectInput {
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 500;
  padding: 10px;
  min-width: 150px;
}

.selectList {
  position: absolute;
  top: 50px;
  right: 0;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  padding: 5px;
  width: 200px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  z-index: 100;
  background-color: var(--white);
}

.selectList > .selectItem {
  padding: 10px;
  width: 100%;
  border-radius: 5px;
  text-align: center;
  font-weight: 500;
}

.selectList > .selectItem:hover {
  cursor: pointer;
  transition: all ease 150ms;
  background-color: var(--menuHover);
}
