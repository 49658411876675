.PR-Tml_Header_Box {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  border-radius: 5px;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
  background-color: var(--white);
}

.PR-Tml_AddPhoto {
  display: flex;
  font-weight: 600;
  align-items: center;
}

.PR-Tml_AddPhoto:hover {
  cursor: pointer;
  text-decoration: underline;
}

.PR-Timeline_LazyLoadWrapper > img {
  object-fit: cover;
}

/* --- Timeline List ↓ */

.timeline_list_con {
  padding: 20px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
  background-color: white;
}

.tml_menu_con {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
}

button.tml_menu_btn {
  padding: 3px;
  width: 30px;
  height: 30px;
  font-size: 2rem;
  display: flex;
  align-items: center;
  border-radius: 50%;
  border: none;
  justify-content: center;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  background-color: white;
}

ul.tml_menu_dropdown {
  position: absolute;
  top: 30px;
  right: 0;
  display: flex;
  flex-direction: column;
  padding: 5px;
  width: 120px;
  border-radius: 5px;
  z-index: 2;
  box-sizing: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  background: white;
}

ul.tml_menu_dropdown > li {
  padding: 5px;
  border-radius: 5px;
  font-size: 0.9rem;
  width: 100%;
  font-weight: 500;
}

ul.tml_menu_dropdown > li:hover {
  cursor: pointer;
  transition: all ease 100ms;
  background-color: var(--menuHover);
}

/* ---------------------------------------------------------- */
/* ----- Media Queries ↓ ------------------------------------ */
/* ---------------------------------------------------------- */

@media all and (max-width: 500px) {
  .PR-Tml_Content_Box {
    grid-template-columns: repeat(3, 1fr);
  }
  .timeline_list_con {
    padding: 10px;
  }
  .PR-Tml_Header_Box {
    margin-bottom: 10px !important;
  }
}
