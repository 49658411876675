/* ----------------------------------------- */
/* ----- Edit Post ------------------------- */
/* ----------------------------------------- */

.tml_edit-post_modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);
}

.tml_edit-post_content {
  width: 40%;
  height: auto;
  padding: 1rem;
  border-radius: 5px;
  background-color: white;
}

.tml-edit-post-profile_con {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  margin-bottom: 1rem;
}

.tml_edit-post-profile-img_wrapper {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
}

.tml_edit-post-profile-img_wrapper > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.tml_edit-post-profile-detail_con {
  display: flex;
  flex-direction: column;
}

.tml_edit-post-profile_date {
  font-size: 0.8rem;
  color: gray;
}

textarea.tml_edit-post-profile-comment_inp {
  font-family: inherit;
  resize: none;
  width: 100%;
  min-height: 70%;
  max-height: 70%;
}

.tml_edit-post-controls_con {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1rem;
}

.tml_edit-post-controls_con > button {
  border: none;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  min-width: 100px;
  border-radius: 5px;
  font-weight: 600;
}

.tml_edit-post-controls_con > button:hover {
  cursor: pointer;
  transition: all ease 100ms;
  opacity: 0.95;
}

.tml_edit-post-cancel_btn {
  border: solid 2px var(--primary) !important;
  color: var(--primary) !important;
  background-color: transparent !important;
}

.tml_edit-post-save_btn {
  color: white !important;
  background-color: var(--primary) !important;
}

/* --- Media Query ↓ */

@media all and (max-width: 1500px) {
  .tml_edit-post_content {
    width: 50%;
  }
}

@media all and (max-width: 1400px) {
  .tml_edit-post_content {
    width: 60%;
  }
}

@media all and (max-width: 1000px) {
  .tml_edit-post_content {
    width: 70%;
  }
}

@media all and (max-width: 700px) {
  .tml_edit-post_content {
    width: 80%;
  }
}

@media all and (max-width: 500px) {
  .tml_edit-post_content {
    width: 90%;
  }
}
