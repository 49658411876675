.auth_content {
  padding-top: 120px;
  height: calc(100vh - 80px);
}

.article_signup {
  display: block;
  margin: auto;
}

.con_signup-content {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-heading {
  display: block;
  font-size: 2em;
  font-weight: 700;
  margin-bottom: 10px;
}

.con_signup-key_pub {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
}

.inp_signup-email_pub {
  width: 100%;
}

.inp_signup-key_pub {
  width: 100px;
}

.btn_signpup {
  display: block;
  margin: 20px auto;
  width: 50%;
}

.btn_signpup:hover {
  width: 50%;
}

.box_navigation-links {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: 1rem;
}

.navlink {
  color: var(--primary);
}

@media all and (max-width: 600px) {
  .article_signup {
    width: 80%;
  }
}

@media all and (max-width: 500px) {
  .article_signup {
    width: 85%;
  }

  .btn_signpup {
    width: 100%;
  }

  .form-heading {
    font-size: 1.8em;
  }
}
