.SwiperModal {
  position: fixed;
  top: 0px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5001;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.95);
}

.SwiperCloseModal {
  width: 30px;
  height: 30px;
  fill: rgb(68, 68, 68);
  opacity: 0.9;
}

.SwiperCloseModal:hover {
  cursor: pointer;
  opacity: 1;
  transition: all ease 150ms;
}

.SwiperGrid {
  display: grid;
  width: 1200px;
  height: 90vh;
  grid-template-columns: 75% 25%;
  grid-template-rows: auto;
  border-radius: 5px;
  overflow: hidden;
  background-color: rgb(0, 0, 0);
}

.SwiperSlideBox {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.SwiperHeaderBox {
  grid-row-start: 1;
  grid-column-start: 1;
  grid-column-end: 3;
  padding: 20px;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 1px rgb(231, 231, 231);
  background-color: var(--white);
}

.SwiperRightSidebar {
  grid-column-start: 2;
  padding: 20px;
  height: 100%;
  background-color: var(--white);
}

/* ---------------------------------------------------- */
/* ----- Swiper --------------------------------------- */
/* ---------------------------------------------------- */

.mainSwiper {
  width: 100%;
  height: 65vh;
}

.mainSwiper > .swiper-slide {
  background-size: cover;
  background-position: center;
  text-align: center;
  font-size: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.swiper-slide > img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* ----- Thumbs ----------- ↓ */

.thumbSwiper {
  width: 100%;
  height: 20vh;
  margin-top: 10px;
}

.thumbSwiper .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 0.4;
  overflow: hidden;
}

.thumbSwiper img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.thumbSwiper .swiper-slide-thumb-active {
  opacity: 1;
}

/* ---------------------------------------------------------- */
/* ----- Right Sidebar ↓ ------------------------------------ */
/* ---------------------------------------------------------- */

.SwiperProfileBox {
  width: 100%;
  height: auto;
}

.SwiperProfileHeader {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.SwiperProfileImgBox {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
}

.SwiperProfileImgBox > img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.SwiperProfileDetailBox {
  display: flex;
  flex-direction: column;
  width: 70%;
}

.SwiperProfileDetailBox > .FullName {
  font-weight: 600;
  color: var(--black) !important;
}

.SwiperProfileDetailBox > .DateCreated {
  font-weight: 300;
  font-size: 0.8em;
  color: gray;
}

.SwiperProfileDetailBox > .Title {
  font-weight: 500;
  font-size: 0.8em;
  color: var(--primary);
}


/* ---------------------------------------------------------- */
/* ----- Media Queries ↓ ------------------------------------ */
/* ---------------------------------------------------------- */

@media all and (max-width: 1250px) {
  .SwiperGrid {
    width: 95%;
  }

  .mainSwiper {
    max-height: 70vh;
  }

  .thumbSwiper {
    height: 25vh;
  }
}

@media all and (max-width: 1000px) {
  .SwiperGrid {
    width: 100%;
    height: 100%;
    grid-template-columns: 100%;
    overflow-y: hidden;
  }

  .SwiperRightSidebar {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    z-index: 1000;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.6) !important;
  }

  .SwiperProfileDetailBox {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin-bottom: 5px;
  }

  .SwiperProfileDetailBox > .FullName,
  .SwiperProfileDetailBox > .Title,
  .SwiperProfileDetailBox > .DateCreated,
  .Comment {
    color: var(--white) !important;
  }
}

@media all and (max-width: 800px) {
  .SwiperRightSidebar {
    width: 100%;
  }
}
