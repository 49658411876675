.PR-Dash_Grouping {
  display: block;
  margin: auto;
  width: 1200px;
}

.PR-Dash_RightSidebar_Heading {
  display: block;
  font-size: 1.5em;
  font-weight: 700;
  padding: 20px;
  border-bottom: solid 1px var(--menuHover);
}

.PR-Dash_Grid {
  display: grid;
  grid-template-columns: 250px 1fr;
  gap: 20px;
  width: 100%;
}

.PR-Dash_RightSidebar_Box {
  width: 100%;
  height: calc(100vh - 50vh);
  border-radius: 5px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
  background-color: var(--white);
}

.PR-Dash_RightSidebar_ul {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  overflow: hidden;
  padding: 5px;
}

.PR-Dash_RightSidebar_ul > li {
  list-style-type: none;
  padding: 15px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  border-radius: 5px;
}

.PR-Dash_RightSidebar_ul > li:hover {
  cursor: pointer;
  transition: all ease 150ms;
  background-color: var(--menuHover);
}

.PR-Dash_RightSidebar_icon {
  width: 30px;
  height: 30px;
  fill: var(--black);
}

/* --------------------------------------------------- */
/* ----- Main Content -------------------------------- */
/* --------------------------------------------------- */

.PR-Dash_ExternalPages_Box {
  display: block;
  margin: auto;
  width: 100%;
  height: 100%;
}

.con_dash-options {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 20px;
  overflow: hidden;
  padding: 20px;
  width: 100%;
}

/* ---------------------------------------------------------- */
/* ----- Media Queries ↓ ------------------------------------ */
/* ---------------------------------------------------------- */

@media all and (max-width: 1250px) {
  .PR-Dash_Grouping {
    width: 95%;
  }

  .PR-Dash_RightSidebar_Heading {
    font-size: 1.4em;
  }

  .PR-Dash_Grid {
    grid-template-columns: 200px 1fr;
  }
}

@media all and (max-width: 700px) {
  .PR-Dash_Grid {
    grid-template-columns: 100%;
  }

  .PR-Dash_RightSidebar_Box {
    height: fit-content;
  }

  .PR-Dash_RightSidebar_ul {
    flex-direction: row;
  }
}

/* Mobile Devices ↓ */

@media all and (max-width: 500px) {

  .PR-Dash_Grid {
    gap: 10px;
  }

  .PR-Dash_RightSidebar_ul > li {
    font-size: 0.9em;
  }

  .PR-Dash_RightSidebar_icon {
    width: 25px;
    height: 25px;
  }

  .PR-Dash_RightSidebar_Heading {
    padding: 10px;
  }

  .PR-Dash_RightSidebar_ul > li {
    padding: 10px;
  }
}

@media all and (max-width: 320px) {
  .PR-Dash_RightSidebar_icon {
    display: none;
  }
}
