.pwd_content {
  padding-top: 120px;
  height: calc(100vh - 80px);
}

.reset-pwd_content {
  width: 500px;
  display: flex;
  flex-direction: column;
  margin: auto;
  justify-content: center;
}

.reset-pwd_heading_con {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}

.reset-pwd_heading_con > h1 {
  font-size: 2em;
}

.reset-pwd_heading_con > h2 {
  font-weight: 300;
  margin-top: 1rem;
}

.reset-pwd_inp_con {
  display: flex;
  flex-direction: column;
}

/* --- 📌Media Queries ↓ */

@media all and (max-width: 550px) {
  .reset-pwd_content {
    width: 85%;
  }

  .reset-pwd_heading_con > h1 {
    font-size: 1.8em;
  }
}
