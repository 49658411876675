.Content {
  padding: 80px 0 80px 0;
}

/* --- 📌Media Queries ↓ */

@media all and (max-width: 500px) {
  .Content {
    padding: 70px 0 70px 0;
  }
}
