@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  --primary: #1368ce;
  --secondary: #26890c;
  --thirdary: #e21b3c;
  --fourthly: #46178f;
  --black: #333333;
  --white: #ffffff;
  --menuHover: #e7edf1;
}

* {
  padding: 0;
  margin: 0;
  font-size: 16px;
  color: var(--black);
  box-sizing: border-box;
  line-height: 1.5;
}

html {
  overscroll-behavior: none;
}

body {
  font-family: "Poppins", sans-serif;
  width: 100%;
  height: 100%;
  overscroll-behavior: none;
  overflow-y: scroll;
  background-color: #f3f8ff;
}

input[type="text"],
input[type="date"],
input[type="email"],
input[type="password"],
input[type="number"] {
  padding: 10px;
  border: none;
  border-radius: 5px;
  border: solid 1px lightgray;
  outline-style: none;
}

input[type="date"] {
  display: block;
  -webkit-appearance: textfield !important;
  -moz-appearance: textfield !important;
  min-height: 1.2em !important;
}

textarea {
  padding: 10px;
  border: none;
  border-radius: 5px;
  border: solid 1px lightgray;
  outline-style: none;
  resize: vertical;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box !important;
}

.FULL_WIDTH {
  width: 100%;
  height: auto;
}

.MEDIUM_WIDTH {
  width: 700px;
  height: auto;
}

.SMALL_WIDTH {
  width: 500px;
  height: auto;
}

.PAGE-HEADING {
  display: block;
  margin: auto;
  margin-bottom: 30px;
  font-size: 2em;
  width: 100%;
}

.SUB_HEADING {
  font-size: 1.5em !important;
  font-weight: 400 !important;
}

.DESKTOP_WIDTH {
  width: 1200px;
  display: block;
  margin: auto;
}

/* ---------------------------------------------------------- */
/* ----- Media Queries ↓ ------------------------------------ */
/* ---------------------------------------------------------- */

@media all and (max-width: 1100px) {
  .DESKTOP_WIDTH {
    width: 100%;
  }

  .MEDIUM_WIDTH {
    width: 100%;
  }
}
