.WidgetBox {
  position: relative;
  border-radius: 5px;
  border: solid 1px rgb(228, 228, 228);
  overflow: hidden;
  width: 100%;
  background-color: white;
}

.PL-Feed_Grouping {
  display: grid;
  width: 1000px;
  height: fit-content;
  grid-template-columns: 1fr 500px 1fr;
  gap: 20px;
  margin: auto;
}

/* ----------------------------------------------- */
/* ----- Left Sidebar ---------------------------- */
/* ----------------------------------------------- */

.PL-Feed_LeftSidebar {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.PL-Feed_ProfileImg_Wrapper {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  overflow: hidden;
  background-color: rgb(240, 240, 240);
}

.PL-Feed_ProfileLogo_Wrapper {
  position: relative;
  top: -40px;
  left: 0;
  right: 0;
  width: 80px;
  height: 80px;
  display: block;
  margin: auto;
  border-radius: 50%;
  border: solid 2px white;
  overflow: hidden;
  background-color: rgb(245, 245, 245);
}

.PL-Feed_ProfileImg_Wrapper > img,
.PL-Feed_ProfileLogo_Wrapper > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.PL-Feed_ProfileMeta_Box {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -40px;
  padding: 20px;
}

.PL-Feed_ProfileMeta_Box > .PL-Feed_ProfileName {
  font-weight: 700;
  margin-bottom: 10px;
  font-size: 1.3em;
}

.PL-Feed_ProfileMeta_Box > .PL-Feed_ProfileAbout {
  font-weight: 400;
  font-size: 0.9em;
  text-align: center;
}

.feed_adventure-blog_con {
  position: relative;
  padding: 1.5rem;
  border-radius: 5px;
  border: solid 1px rgb(228, 228, 228);
  overflow: hidden;
  margin-bottom: 20px;
  background-color: white;
}

.feed_adventure-blog_img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

.feed_adventure-blog_con > h2 {
  font-size: 1rem;
  margin-bottom: 1rem;
  position: inherit;
}

.feed_adventure-blog_con > p {
  margin-bottom: 1.5rem;
  font-size: 0.9rem;
  position: inherit;
}

.feed_adventure-blog_con > button {
  width: 100%;
  display: block;
  margin: auto;
  padding: 0.5rem;
  border: none;
  border-radius: 5px;
  color: var(--white);
  position: inherit;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  background-color: var(--primary);
}

.feed_adventure-blog_con > button:hover {
  cursor: pointer;
  transition: all ease-in-out 100ms;
  background-color: #1367ceee;
}

/* ----------------------------------------------- */
/* ----- Right Sidebar --------------------------- */
/* ----------------------------------------------- */

.PL-Feed_RightSidebar {
  width: 100%;
  height: 50vh;
}

.PL-Feed_SidebarHeading {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: 1.15em;
  padding: 15px 15px 0 15px;
}

ul.PL-Feed_RecentPost_List {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 5px;
}

ul.PL-Feed_RecentPost_List > li {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
}

ul.PL-Feed_RecentPost_List > li:hover {
  cursor: pointer;
  border-radius: 5px;
  background-color: var(--menuHover);
}

ul.PL-Feed_RecentPost_List > li > .PL-Feed_RecentPostTitle {
  display: block;
  font-size: 0.9em;
  font-weight: 500;
  margin-bottom: 5px;
}

ul.PL-Feed_RecentPost_List > li > .PL-Feed_RecentPostDateCreated {
  display: block;
  font-size: 0.75em;
  font-weight: 400;
  color: gray;
}

/* ----------------------------------------------- */
/* ----- Main Feed ------------------------------- */
/* ----------------------------------------------- */

.PL-Feed_Main {
  height: auto;
}

.PL-Feed_Card {
  overflow: hidden;
  width: 100%;
  height: fit-content;
  margin-bottom: 20px;
}

.PL-Feed_CardHeader_Box {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
}

.PL-Feed_CardHeaderImg_Wrapper {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  background-color: rgb(240, 240, 240);
}

.PL-Feed_CardHeaderImg_Wrapper > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.PL-Feed_CardMeta_Box {
  display: flex;
  flex-direction: column;
}

.PL-Feed_CardEnteredBy {
  font-size: 0.9em;
  font-weight: 500;
}

.PL-Feed_CardDateCreated {
  display: block;
  font-weight: 300;
  font-size: 0.8em;
  color: gray;
}

.PL-Feed_CardTitle {
  font-size: 0.8em;
  font-weight: 500;
  color: var(--primary);
}

.PL-Feed_CardComment_Box {
  padding: 0px 10px 0 10px;
  margin-bottom: 10px;
}

.PL-Feed_CardComment_Box > p {
  font-size: 0.9em;
}

/* ---- Image Load ↓ */

.PL-Feed_CardThumbImg_Wrapper {
  position: relative;
  max-height: 450px;
}

.PL-Feed_CardThumbImg_Wrapper > img[src=""] {
  display: none;
}

.PL-Feed_CardThumb_Grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  gap: 5px;
  height: fit-content;
  background-color: rgb(247, 247, 247);
}

.PL-Feed_LazyLoadWrapper {
  display: flex !important;
  cursor: pointer;
}

.PL-Feed_LazyLoadWrapper > img {
  object-fit: cover;
}

.Box0 {
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 1;
}

.Box1 {
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 2;
  grid-row-end: 2;
  height: 100px;
  overflow: hidden;
}

.Box2 {
  grid-column-start: 2;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 2;
  height: 100px;
  overflow: hidden;
}

.Box3 {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column-start: 3;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 2;
  height: 100px;
  overflow: hidden;
}

.CardMorePhotos {
  cursor: pointer;
  position: absolute;
  grid-column-start: 3;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 2;
  height: auto;
  overflow: hidden;
  display: flex;
  color: #fff;
  z-index: 500;
  font-weight: 600;
  font-size: 1.5em;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}

/* ---------------------------------------------------- */
/* ----- Responsive Queries --------------------------- */
/* ---------------------------------------------------- */

@media all and (max-width: 1050px) {
  .PL-Feed_Grouping {
    width: 750px;
    grid-template-columns: 1fr 500px;
  }

  .PL-Feed_RightSidebar {
    display: none;
  }
}

@media all and (max-width: 850px) {
  .PL-Feed_Grouping {
    width: 500px;
    display: flex;
    flex-direction: column;
    gap: 0;
  }

  .PL-Feed_Profile_Box {
    display: none;
  }

  .feed_adventure-blog_con > h2 {
    font-size: 1.2rem;
  }
}

@media all and (max-width: 600px) {
  .PL-Feed_Section {
    margin-top: 70px;
  }
}

@media all and (max-width: 550px) {
  .PL-Feed_Grouping {
    width: 95%;
  }
}

@media all and (max-width: 500px) {
  .PL-Feed_ProfileAbout {
    display: none;
  }

  .PL-Feed_ProfileMeta_Box > .PL-Feed_ProfileName {
    margin-bottom: 0;
  }

  .PL-Feed_Card,
  .feed_adventure-blog_con {
    margin-bottom: 10px;
  }

  .PL-Feed_ProfileImg_Wrapper {
    height: 130px;
  }

  .PL-Feed_ProfileLogo_Wrapper {
    top: -50px;
    width: 100px;
    height: 100px;
  }

  .PL-Feed_ProfileMeta_Box {
    margin-top: -50px;
  }
}
