.PL-About_Grouping {
  width: 1000px;
  padding: 20px;
  margin-bottom: 20px;
}

.con_page-heading {
  margin-bottom: 20px;
}

.PageHeading {
  font-size: 2em;
}

.ProfileCardList {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  width: 100%;
  height: auto;
}

.ProfileCardItem {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
  background-color: white;
}

.ProfileCardHeader {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ProfileCardDetail {
  margin-top: 150px;
  margin-bottom: 20px;
}

.ProfileCardImage {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  background-color: rgb(235, 235, 235);
}

.ProfileCardImage > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ProfileCardName {
  display: block;
  text-align: center;
  margin-top: 30px;
  font-size: 1.2em;
  font-weight: 600;
}

.ProfileCardDescription {
  margin-top: 220px;
  padding: 0px 20px 0px 20px;
  text-align: center;
  height: 150px;
  overflow-y: auto;
}

.description {
  color: gray;
}

.read-or-hide {
  cursor: pointer;
  font-size: 0.85em;
  color: var(--primary);
  font-style: italic;
}

.ProfileCardContact {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 60px;
  gap: 20px;
  width: 100%;
}

.ProfileCardIcon {
  fill: gray;
  font-size: 20px;
  cursor: pointer;
}

.gray {
  background: rgb(72, 84, 96);
  background: linear-gradient(
    180deg,
    rgba(72, 84, 96, 1) 0%,
    rgba(114, 130, 147, 1) 100%
  );
  width: 100%;
}

/* ---------------------------------------------------------- */
/* ----- Media Queries ↓ ------------------------------------ */
/* ---------------------------------------------------------- */

@media all and (max-width: 1050px) {
  .PL-About_Grouping {
    width: 95% !important;
  }

  .ProfileCardList {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media all and (max-width: 750px) {
  .ProfileCardList {
    grid-template-columns: 100%;
  }
}

@media all and (max-width: 500px) {
  .PL-About_Grouping {
    padding: 0px;
  }

  .con_page-heading {
    margin: 10px 0 10px 0;
  }

  .ProfileCardList {
    gap: 10px;
  }

  .description {
    padding: 0 10px 0 10px;
  }
}
